@import "../../sass/colors.scss";

$height: 60px;
$disabled-color: lightgrey !important;
$disabled-background: transparent !important;

.disabled {
	button {
		color: $disabled-color;
		border-color: $disabled-color;
		background-color: $disabled-background;
		cursor: default !important;

		&::before {
			color: $disabled-color;
			border-color: $disabled-color;
			background-color: $disabled-background;
		}

		&:hover {
			background-color: $disabled-background;
			border-color: $disabled-color;

			&::before {
				color: $disabled-color;
				border-color: $disabled-color;
				background-color: $disabled-background;
			}
		}
	}
}

.button-wrapper {
	margin: 1em 0;
	width: 100%;

	button {
		width: 100%;
		height: $height;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border: 1px solid $dark-green;
		padding: 0 4.5em 0 1.5em;
		cursor: pointer;
		font-family: Montserrat, sans-serif;
		font-size: 18px;
		font-weight: 600;
		transition: all 0.3s ease-in-out;
		position: relative;

		&::before {
			content: " \203A";
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			width: 50px;
			border-left: 1px solid $dark-green;
			font-size: 30px;
			font-weight: 500;
			padding-top: 8px;
		}

		&.primary {
			color: $font-color;
			background-color: $dark-green;

			&::before {
				background-color: $light-green;
				color: $font-color;
			}

			&:hover {
				color: $dark-green;
				background-color: transparent;

				&::before {
					color: $dark-green;
					background-color: transparent;
				}
			}
		}

		&.secondary {
			color: $dark-green;
			background-color: transparent;

			&::before {
				color: $dark-green;
				background-color: transparent;
			}

			&:hover {
				color: $font-color;
				background-color: $dark-green;

				&::before {
					background-color: $light-green;
					color: $font-color;
				}
			}
		}
	}
}
