@import "../../sass/vars.scss";
@import "../../sass/colors.scss";
@import "../../sass/mixins.scss";

#views-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  min-height: 340px;
  margin-top: 3em;
  gap: 40px;

  a {
    position: relative;
    border: 1px solid $whitesmoke;
    margin-bottom: 3em;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      top: -5px;
      height: 5px;
      background-color: transparent;
    }

    img {
      border: 1px solid $whitesmoke;
      width: 240px;
    }

    &:hover {
      @include box-shadow(0, 20px, 30px, $whitesmoke);

      &::before {
        background-color: $dark-green;
      }
    }
  }

  #thumbnail-skeleton {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 302px;
    min-height: 302px;
    margin-bottom: 3em;
    padding: 10px;
    background-color: rgba(245, 245, 245, 0.5);
  }
}
