#public-wrapper {
	display: flex;
	flex-direction: row;
	background-color: white;
	width: 100%;
	height: 100vh;

	#public-content {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 50%;
		height: 100vh;
		flex-grow: 1;

		#public-title {
			font-family: Montserrat;
			font-weight: 600;
			font-size: 30px;
		}
	}

	#public-image {
		display: flex;
		justify-content: center;
		align-content: flex-start;
		width: 50vw;
		max-width: 1922px;
		height: 100vh;
		background-image: url("../../images/login-background.png");
		background-size: cover;
		background-repeat: no-repeat;
		background-position-y: inherit;

		#logo {
			height: 64px;
			margin-top: 250px;
		}
	}
}
