@import "../../sass/colors.scss";
@import "../../sass/mixins.scss";

$menu-item-height: 40px;

#navigation {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: $dark-green;
  font-family: "Open Sans", sans-serif;
  color: $white;
  @include transition(width, 0.3s, ease-in-out);
  @include transition(background-color, 0.3s, ease-in-out);

  #menu-header {
    display: flex;
    width: 100%;
  }

  #menu-body {
    flex-grow: 2;
    position: relative;
  }

  #menu-foot {
    border-top: 1px solid $white;
    padding: 1em;
  }

  &.collapsed {
    width: 67px;
    background-color: $logo-dark;

    #menu-header {
      flex-direction: column;

      img:first-of-type {
        display: none;
      }

      img:last-of-type {
        padding: 1em;
      }
    }

    #menu-body {
      ul {
        display: none;
      }

      div {
        display: block;
        cursor: pointer;

        &::before {
          display: flex;
          align-items: center;
          justify-content: center;
          content: " \203A";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          font-size: 40px;
          font-weight: 100;
          color: $white;
        }
      }
    }

    #menu-foot {
      p {
        visibility: hidden;
      }

      a,
      button {
        display: block;
        position: relative;
        padding-left: 18%;
        cursor: pointer;
        text-indent: -9999px;
        padding: 0.5em 0;
        outline: none;
        border: none;
        text-decoration: none;
        width: 35px;
        background-color: transparent;
        @include border-radius(19px);

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          background-repeat: no-repeat;
          background-position: center;
        }

        &:hover {
          background-color: rgba(43, 48, 53, 0.1);
        }
      }

      a::before {
        background-image: url("../../images/visit-us.svg");
      }

      button::before {
        background-image: url("../../images/logout-icon.svg");
      }
    }
  }

  &:not(.collapsed) {
    width: 400px;

    #menu-header {
      flex-direction: row;
      padding: 10% 0;
      justify-content: center;
      width: 100%;

      img:first-of-type {
        margin: 0 5%;
        cursor: pointer;
        position: fixed;
        top: 40px;
        left: -68px;
        width: 40px;
      }

      img:last-of-type {
        width: 55%;
      }
    }

    #menu-body {
      div {
        display: none;
      }

      ul {
        width: 90%;
        margin: 0;
        padding: 5%;
        list-style-type: none;

        li {
          position: relative;
          margin-bottom: 0.25em;
          @include border-radius(19.5px);
          @include transition(height, 0.3s, ease-in-out);

          & > button {
            &:hover {
              &::before {
                content: " \203A";
                transform: rotate(90deg);
                position: absolute;
                top: 0;
                right: 14px;
                bottom: 0;
                width: 10px;
                margin-top: 6px;
                font-size: 26px;
                font-weight: lighter;
              }
            }
          }

          button,
          a {
            display: block;
            position: relative;
            text-decoration: none;
            text-transform: uppercase;
            width: 100%;
            padding-left: 10%;
            line-height: $menu-item-height;
            color: $white;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 1px;
            background-color: transparent;
            border: none;
            text-align: left;
            cursor: pointer;
            @include border-radius(19.5px);
            outline: none;
          }

          & > ul {
            display: none;

            button,
            a {
              font-weight: 300;
              letter-spacing: 0px;
              text-transform: none;
              width: auto;
            }

            & > li {
              & > a {
                &:hover {
                  &::before {
                    content: " \203A";
                    position: absolute;
                    top: 0;
                    right: 15px;
                    bottom: 0;
                    width: 10px;
                    margin-top: -2px;
                    font-size: 26px;
                    font-weight: lighter;
                  }
                }
              }
            }
          }

          &:hover {
            background-color: rgba(43, 48, 53, 0.1);
          }

          &.active {
            background-color: rgba(43, 48, 53, 0.1);

            button {
              background-color: rgba(43, 48, 53, 0.1);
            }

            & > ul {
              display: block;

              button,
              a {
                &:hover {
                  background-color: transparent;
                }
              }
            }

            &:hover {
              &::before {
                content: "";
              }
            }
          }
        }
      }
    }

    #menu-foot {
      p {
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $white;
        font-weight: 600;
        font-size: 12px;
      }

      a,
      button {
        display: block;
        position: relative;
        font-size: 14px;
        color: $white;
        font-weight: 300;
        letter-spacing: 0;
        height: $menu-item-height;
        line-height: $menu-item-height;
        padding-left: 18%;
        cursor: pointer;
        background-color: transparent;
        outline: none;
        border: none;
        text-decoration: none;
        @include border-radius(19.5px);

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          background-repeat: no-repeat;
          background-position: center;
          width: 20%;
        }

        &:hover {
          background-color: rgba(43, 48, 53, 0.1);
        }
      }

      a::before {
        background-image: url("../../images/visit-us.svg");
      }

      button {
        width: 100%;
        text-align: left;

        &::before {
          background-image: url("../../images/logout-icon.svg");
        }
      }
    }
  }
}
