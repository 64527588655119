@mixin placeholder {
	::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		@content;
		opacity: 1; //for Mozilla
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		@content;
	}

	::-ms-input-placeholder {
		/* Microsoft Edge */
		@content;
	}
}

@mixin border-radius($radius) {
	-moz-border-radius: $radius;
	-webkit-border-radius: $radius;
	border-radius: $radius;
}

@mixin border-radii($topleft, $topright, $bottomright, $bottomleft) {
	-moz-border-radius-bottomleft: $bottomleft;
	-moz-border-radius-bottomright: $bottomright;
	-moz-border-radius-topleft: $topleft;
	-moz-border-radius-topright: $topright;
	-webkit-border-bottom-left-radius: $bottomleft;
	-webkit-border-bottom-right-radius: $bottomright;
	-webkit-border-top-left-radius: $topleft;
	-webkit-border-top-right-radius: $topright;
	border-bottom-left-radius: $bottomleft;
	border-bottom-right-radius: $bottomright;
	border-top-left-radius: $topleft;
	border-top-right-radius: $topright;
}

@mixin box-shadow($left, $top, $radius, $color) {
	-moz-box-shadow: $left $top $radius $color;
	-webkit-box-shadow: $left $top $radius $color;
	box-shadow: $left $top $radius $color;
}

@mixin transition($property, $duration, $easing: linear) {
	-moz-transition: $property $duration $easing;
	-webkit-transition: $property $duration $easing;
	transition: $property $duration $easing;
}
