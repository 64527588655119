.loading {
	min-height: 120px;
	position: relative;

	&::before {
		background-color: rgba(245, 245, 245, 0.5);
		background-image: url("../images/loader.svg");
		background-position: center;
		background-repeat: no-repeat;
		bottom: 0;
		content: "";
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1000;
	}
}
