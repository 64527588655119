@import "../../sass/colors.scss";
@import "../../sass/vars.scss";

#home {
	padding: 2% 4%;
	font-family: $font-family;

	h1 {
		color: $font-color;
		font-size: 40px;
	}

	& > p {
		font-size: 14px;
		line-height: 24px;
		font-weight: lighter;
	}

	.link-box-header {
		width: 50vw;
		margin: 2em auto;
		padding: 10;
		text-align: center;

		.header-text {
			font-size: 24px;
			font-weight: bold;
		}
	}
}
