@import "../../sass/colors.scss";

.link-box-wrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;

	.link-box {
		font-family: "Montserrat", sans-serif;
		font-weight: bold;
		border: 1px solid $whitesmoke;
		text-align: center;
		padding: 1em;
		width: 45%;

		h4 {
			font-size: 20px;
			margin-bottom: 0.5em;
		}

		p {
			font-size: 16px;
			max-width: 400;
			margin: 1em auto;
		}

		button {
			// max-width: 300px;
			width: auto;
			margin: auto;
		}

		a {
			text-decoration: none;
		}
	}
}
